<template>
  <v-layout v-if="getPermission('product:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 60vh; position: relative"
      > -->
      <v-col md="10" offset-md="1" class="pb-0">
        <v-checkbox
          class="mt-0 width-100"
          color="cyan"
          v-model.trim="productCreate.show_name"
        >
          <template v-slot:label>
            <label class="custom-form-label m-0 width-100"
              >Show name, qty, price in Document</label
            >
          </template>
        </v-checkbox>
        <v-text-field
          dense
          filled
          label="Name"
          solo
          flat
          color="cyan"
          v-model.trim="productCreate.name"
          :rules="[
            validateRules.required(productCreate.name, 'Name'),
            validateRules.minLength(productCreate.name, 'Name', 1),
            validateRules.maxLength(productCreate.name, 'Name', 255)
          ]"
        ></v-text-field>
        <v-textarea
          dense
          filled
          label="Description"
          solo
          flat
          row-height="20"
          auto-grow
          color="cyan"
          v-model.trim="productCreate.description"
          :rules="[
            validateRules.minLength(
              productCreate.description,
              'Description',
              1
            ),
            validateRules.maxLength(
              productCreate.description,
              'Description',
              1024
            )
          ]"
        ></v-textarea>
        <div class="d-flex">
          <v-text-field
            dense
            filled
            color="cyan"
            label="Reference"
            solo
            flat
            :rules="[
              validateRules.minLength(productCreate.reference, 'Reference', 1),
              validateRules.maxLength(productCreate.reference, 'Reference', 100)
            ]"
            class="pr-2 width-100"
            v-model.trim="productCreate.reference"
          ></v-text-field>
          <v-text-field
            dense
            filled
            label="Part Number"
            solo
            flat
            color="cyan"
            class="pr-2 width-100"
            :rules="[
              validateRules.minLength(
                productCreate.part_number,
                'Part Number',
                1
              ),
              validateRules.maxLength(
                productCreate.part_number,
                'Part Number',
                100
              )
            ]"
            v-model.trim="productCreate.part_number"
          ></v-text-field>
          <v-text-field
            dense
            filled
            color="cyan"
            label="Barcode"
            solo
            flat
            class="pl-2 width-100"
            readonly
            v-model.trim="barcode"
          ></v-text-field>
        </div>
        <template>
          <div class="d-flex">
            <v-text-field
              v-if="false"
              dense
              filled
              color="cyan"
              label="UPC"
              solo
              flat
              :rules="[
                validateRules.minLength(productCreate.upc, 'UPC', 1),
                validateRules.maxLength(productCreate.upc, 'UPC', 100)
              ]"
              v-model.trim="productCreate.upc"
              class="pr-2 width-100"
            ></v-text-field>
            <v-text-field
              v-if="false"
              dense
              filled
              label="SKU"
              solo
              flat
              color="cyan"
              :rules="[
                validateRules.minLength(productCreate.sku, 'SKU', 1),
                validateRules.maxLength(productCreate.sku, 'SKU', 100)
              ]"
              class="pl-2 width-100"
              v-model.trim="productCreate.sku"
            ></v-text-field>
          </div>
          <div class="d-flex">
            <v-text-field
              v-if="false"
              dense
              filled
              color="cyan"
              label="HSN Code"
              solo
              flat
              :rules="[
                validateRules.minLength(productCreate.hsn_code, 'HSN Code', 1),
                validateRules.maxLength(productCreate.hsn_code, 'HSN Code', 100)
              ]"
              class="pr-2 width-100"
              v-model.trim="productCreate.hsn_code"
            ></v-text-field>
            <v-text-field
              v-if="false"
              dense
              filled
              color="cyan"
              label="EAN"
              solo
              flat
              :rules="[
                validateRules.minLength(productCreate.ean, 'EAN', 1),
                validateRules.maxLength(productCreate.ean, 'EAN', 100)
              ]"
              class="pl-2 width-100"
              v-model.trim="productCreate.ean"
            ></v-text-field>
          </div>
          <div class="d-flex">
            <v-text-field
              v-if="false"
              dense
              filled
              label="ISBN"
              solo
              flat
              color="cyan"
              class="pr-2 width-100"
              :rules="[
                validateRules.minLength(productCreate.isbn, 'ISBN', 1),
                validateRules.maxLength(productCreate.isbn, 'ISBN', 100)
              ]"
              v-model.trim="productCreate.isbn"
            ></v-text-field>
            <v-text-field
              v-if="false"
              dense
              filled
              label="Part Number"
              solo
              flat
              color="cyan"
              class="pl-2 width-100"
              :rules="[
                validateRules.minLength(
                  productCreate.part_number,
                  'Part Number',
                  1
                ),
                validateRules.maxLength(
                  productCreate.part_number,
                  'Part Number',
                  100
                )
              ]"
              v-model.trim="productCreate.part_number"
            ></v-text-field>
          </div>
        </template>
        <div class="d-flex" v-if="false">
          <label class="custom-form-label">Tax Preference</label>
          <v-radio-group
            class="pt-0 mt-0 mb-2"
            dense
            row
            color="cyan"
            v-model.trim="productCreate.taxable"
          >
            <v-radio label="Taxable" color="cyan" :value="true"></v-radio>
            <v-radio label="Non-Taxable" color="cyan" :value="false"></v-radio>
          </v-radio-group>
        </div>
        <div class="d-flex">
          <template>
            <v-text-field
              dense
              color="cyan"
              filled
              label="Re-Order Level"
              v-mask="'######'"
              solo
              flat
              :rules="[
                validateRules.minLength(
                  productCreate.reorder_level,
                  'Re-Order Level',
                  1
                ),
                validateRules.maxLength(
                  productCreate.reorder_level,
                  'Re-Order Level',
                  100
                )
              ]"
              class="pr-2 width-100"
              v-model.trim="productCreate.reorder_level"
            ></v-text-field>
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="unitList"
              label="Unit"
              solo
              flat
              append-outer-icon="mdi-cog"
              v-on:click:append-outer="manageUnitDialog = true"
              item-text="text"
              item-value="id"
              class="pl-2 width-100"
              v-model.trim="productCreate.unit"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Unit(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </template>

          <template v-if="productCreate.product_type == 'service'">
            <v-text-field
              dense
              filled
              color="cyan"
              label="Charges"
              solo
              flat
              :rules="[
                validateRules.required(productCreate.charges, 'Charges')
              ]"
              class="pr-2 width-100"
              v-model.trim="productCreate.charges"
            ></v-text-field>
          </template>
          <v-autocomplete
            v-if="false && productCreate.taxable"
            dense
            color="cyan"
            item-color="cyan"
            :items="taxList"
            filled
            label="Tax"
            solo
            flat
            item-text="name"
            item-value="id"
            :rules="[validateRules.required(productCreate.tax, 'Tax')]"
            v-model.trim="productCreate.tax"
            class="pl-2 width-100"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="'No Tax(s) Found.'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-if="false"
            :items="taxExemptionList"
            dense
            filled
            color="cyan"
            item-color="cyan"
            label="Tax Exemption"
            solo
            flat
            item-text="name"
            item-value="id"
            :rules="[
              validateRules.required(
                productCreate.tax_exemption,
                'Tax Exemption'
              )
            ]"
            v-model.trim="productCreate.tax_exemption"
            class="pl-2 width-100"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="'No Tax Exemption(s) Found.'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <template>
          <div class="d-flex">
            <v-autocomplete
              :items="supplierList"
              dense
              filled
              color="cyan"
              item-color="cyan"
              label="Supplier"
              solo
              flat
              item-text="display_name"
              item-value="id"
              v-model.trim="productCreate.supplier"
              class="pr-2 width-100"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Supplier(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-autocomplete
              :items="brandList"
              dense
              filled
              color="cyan"
              item-color="cyan"
              label="Brand"
              solo
              flat
              append-outer-icon="mdi-cog"
              v-on:click:append-outer="manageBrandDialog = true"
              item-text="text"
              item-value="id"
              class="pl-2 width-100"
              v-model.trim="productCreate.brand"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Brand(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
          <div class="d-flex">
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="manufacturerList"
              label="Manufacturer"
              solo
              flat
              append-outer-icon="mdi-cog"
              v-on:click:append-outer="manageManufacturerDialog = true"
              item-text="text"
              item-value="id"
              class="pr-2 width-100"
              v-model.trim="productCreate.manufacturer"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Manufacturer(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="categoryList"
              label="Category"
              solo
              flat
              append-outer-icon="mdi-cog"
              v-on:click:append-outer="manageCategoryDialog = true"
              item-text="text"
              item-value="id"
              class="pl-2 width-100"
              v-model.trim="productCreate.category"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Category(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
          <div class="d-flex" v-if="false">
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="unitList"
              label="Unit"
              solo
              flat
              append-outer-icon="mdi-cog"
              v-on:click:append-outer="manageUnitDialog = true"
              item-text="text"
              item-value="id"
              class="pr-2 width-100"
              v-model.trim="productCreate.unit"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Unit(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-if="false && !updateMode"
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="accountingList"
              label="Accounting"
              solo
              flat
              class="pl-2 width-100"
              v-model.trim="productCreate.accounting"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="'No Accounting(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
          <div class="d-flex">
            <v-row>
              <v-col md="4">
                <v-checkbox
                  label="Sellable"
                  class="mt-0"
                  color="cyan"
                  v-model.trim="productCreate.sellable"
                ></v-checkbox>
              </v-col>
              <v-col md="4" v-if="false && !updateMode">
                <v-checkbox
                  label="Manage Stock"
                  class="mt-0"
                  color="cyan"
                  v-model.trim="productCreate.manage_stock"
                ></v-checkbox>
              </v-col>
              <v-col md="4" v-if="false && !updateMode">
                <v-checkbox
                  label="Track Batch"
                  class="mt-0"
                  color="cyan"
                  v-model.trim="productCreate.track_batch"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex">
            <v-text-field
              dense
              filled
              color="cyan"
              label="Purchase Cost"
              solo
              flat
              class="pr-2 width-100"
              type="number"
              min="0"
              max="9999"
              prepend-inner-icon="mdi-currency-usd"
              v-model.trim="productCreate.purchase_cost"
              v-on:keypress="limitDecimal($event, productCreate.purchase_cost)"
            ></v-text-field>
            <v-text-field
              dense
              filled
              color="cyan"
              label="Selling Cost"
              solo
              flat
              class="pl-2 width-100"
              type="number"
              min="0"
              max="9999"
              v-on:keypress="limitDecimal($event, productCreate.selling_cost)"
              prepend-inner-icon="mdi-currency-usd"
              v-model.trim="productCreate.selling_cost"
            ></v-text-field>
            <v-text-field
              v-if="!updateMode"
              dense
              filled
              color="cyan"
              label="Initial Stock"
              solo
              flat
              :rules="
                productCreate.product_type == 'goods'
                  ? [
                      validateRules.required(
                        productCreate.initial_stock,
                        'Initial Stock'
                      )
                    ]
                  : []
              "
              v-mask="'######'"
              class="pl-2 width-100"
              v-model.trim="productCreate.initial_stock"
            ></v-text-field>
          </div>
        </template>
        <template>
          <div class="d-flex">
            <v-text-field
              dense
              filled
              color="cyan"
              label="Weight"
              suffix="kg"
              solo
              flat
              class="pr-2 width-100"
              type="number"
              min="0"
              max="9999"
              v-model.trim="productCreate.weight"
              v-on:keypress="limitDecimal($event, productCreate.weight)"
            ></v-text-field>
            <v-text-field
              dense
              filled
              color="cyan"
              label="Length"
              suffix="mm"
              solo
              flat
              class="pr-2 pl-2 width-100"
              type="number"
              min="0"
              max="9999"
              v-model.trim="productCreate.length"
              v-on:keypress="limitDecimal($event, productCreate.length)"
            ></v-text-field>
            <v-text-field
              dense
              filled
              color="cyan"
              label="Width"
              suffix="mm"
              solo
              flat
              class="pr-2 pl-2 width-100"
              type="number"
              min="0"
              max="9999"
              v-model.trim="productCreate.width"
              v-on:keypress="limitDecimal($event, productCreate.width)"
            ></v-text-field>
            <v-text-field
              dense
              filled
              color="cyan"
              label="Height"
              suffix="mm"
              solo
              flat
              class="pl-2 width-100"
              type="number"
              min="0"
              max="9999"
              v-model.trim="productCreate.height"
              v-on:keypress="limitDecimal($event, productCreate.height)"
            ></v-text-field>
          </div>
          <div v-if="getPermission('warranty:create')">
            <v-checkbox
              class="mt-0 width-100"
              color="cyan"
              v-model.trim="warrantyActive"
            >
              <template v-slot:label>
                <label class="custom-form-label m-0 width-100">Warranty</label>
              </template>
            </v-checkbox>
            <template v-if="warrantyActive">
              <template v-for="(warranty, index) in productCreate.warranty">
                <div class="d-flex" :key="index">
                  <v-text-field
                    dense
                    v-mask="'###'"
                    filled
                    color="cyan"
                    label="Duration"
                    solo
                    flat
                    class="pr-2 width-100"
                    v-model.trim="warranty.value"
                  ></v-text-field>
                  <v-select
                    :items="warrantyDurationTypes"
                    dense
                    filled
                    color="cyan"
                    hide-details
                    solo
                    flat
                    item-color="cyan"
                    class="pl-2 pr-2 width-100"
                    v-model.trim="warranty.field"
                    label="Type"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <div class="pl-2 width-50">
                    <template v-if="index > 0">
                      <v-btn
                        class="mx-2"
                        color="danger"
                        dark
                        fab
                        small
                        v-on:click="removeWarranty(index, warranty.id)"
                      >
                        <v-icon dark> mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        color="cyan"
                        dark
                        fab
                        small
                        v-on:click="pushWarranty"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <div v-if="getPermission('attachment:create')">
            <label class="custom-form-label width-100"
              >Images
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-2" v-bind="attrs" v-on="on"
                    >mdi-progress-question</v-icon
                  >
                </template>
                <span>More than 5 images are not allowed</span>
              </v-tooltip>
            </label>
            <div>
              <FileTemplate
                allowUpload
                isMinDisplay
                :attachments.sync="productCreate.product_images"
                v-on:file:updated="updateImages"
              ></FileTemplate>
            </div>
          </div>
        </template>
      </v-col>
      <!-- </perfect-scrollbar> -->
    </v-col>
    <v-col
      class="custom-sidebar-container grey lighten-3"
      cols="12"
      md="3"
      sm="3"
    >
      <template v-for="(fieldInfo, indx) in fieldDescriptions">
        <div
          v-if="fieldInfo.field == currentActiveField"
          :key="indx"
          class="help-sidebar"
        >
          <div class="help-sidebar-header">
            <h3 v-if="fieldInfo.title" class="section-header-title">
              {{ fieldInfo.title }}
            </h3>
          </div>
          <p v-if="fieldInfo.description" class="help-sidebar-description">
            {{ fieldInfo.description }}
          </p>
          <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
            {{ fieldInfo.footer }}
          </h5>
          <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
            {{ fieldInfo.footer_content }}
          </p>
        </div>
      </template>
    </v-col>
    <ManageBrand
      :dialog.sync="manageBrandDialog"
      :brand.sync="brandList"
      v-on:close-dialog="manageBrandDialog = false"
      v-on:get-product-brand="getOptions"
    ></ManageBrand>
    <ManageManufacturer
      :dialog.sync="manageManufacturerDialog"
      :manufacturer.sync="manufacturerList"
      v-on:close-dialog="manageManufacturerDialog = false"
      v-on:get-product-manufacturer="getOptions"
    ></ManageManufacturer>
    <ManageCategory
      :dialog.sync="manageCategoryDialog"
      :category.sync="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
    <ManageUnit
      :dialog.sync="manageUnitDialog"
      :unit.sync="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib//warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import {
  QUERY,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR
} from "@/core/services/store/request.module";

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null
    },
    barcodeData: {
      type: String,
      default: null
    },
    updateMode: {
      type: Boolean,
      default: false
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      }
    }
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    FileTemplate,
    ManageBrand,
    ManageManufacturer,
    ManageCategory,
    ManageUnit
  },
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      supplierList: new Array(),
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      manageBrandDialog: false,
      manageManufacturerDialog: false,
      manageCategoryDialog: false,
      manageUnitDialog: false,
      warrantyActive: false,
      productCreate: {
        show_name: true,
        name: null,
        reference: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        part_number: null,
        hsn_code: null,
        description: null,
        directory: null,
        supplier: null,
        brand: null,
        manufacturer: null,
        category: null,
        unit: null,
        tax: null,
        tax_exemption: null,
        accounting: "none",
        product_type: "goods",
        purchase_cost: null,
        selling_cost: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        charges: null,
        images: [],
        product_images: [],
        warranty: [
          {
            id: null,
            value: null,
            field: null
          }
        ]
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type."
        }
      ]
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        if (param && this.lodash.isEmpty(param.warranty)) {
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null
          });
        }
      }
    },
    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function() {
          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);
      }
    },
    "productCreate.product_type"() {
      this.getOptions();
    }
  },
  methods: {
    updateImages(param) {
      this.productCreate.images = param;
    },
    pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id]
            }
          })
          .then(() => {
            _this.productCreate.warranty.splice(index, 1);
          })
          .catch(error => {
            _this.logError(error);
          });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.productCreate.product_type }
        })
        .then(response => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.supplier
          ) {
            _this.supplierList = response.data.supplier;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(response.data.tax, function(
                tax
              ) {
                return tax.default;
              });
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function(taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
          }

          if (!_this.updateMode) {
            if (
              !_this.productId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
            }

            if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;
              if (_this.productCreate.description) {
                _this.productCreate.description =
                  _this.barcodeSetting.description;
              }
            }
          }
        })
        .catch(error => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    }
  },
  mounted() {
    this.getOptions();
  }
};
</script>
